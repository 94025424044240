import React from 'react';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';

import Hero from 'components/hero/TwoColumnWithFeaturesAndTestimonial.js';
import Features from 'components/features/ThreeColWithSideImage.js';
import MainFeature from 'components/features/TwoColWithTwoHorizontalFeaturesAndButton.js';
import FeatureStats from 'components/features/ThreeColCenteredStatsPrimaryBackground.js';
import Testimonial from 'components/testimonials/TwoColumnWithImageAndRating.js';
import ContactUsForm from 'components/forms/SimpleContactUs.js';

import Footer from 'components/footers/MiniCenteredFooter';

const HighlightedText = tw.span`text-primary-700`;

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <FeatureStats />
      <Features
        heading={
          <>
            Our Amazing <HighlightedText>Workshop.</HighlightedText>
          </>
        }
        id="about"
      />
      <MainFeature
        heading={
          <>
            Facilitated by Environmental{'       '}
            <HighlightedText>Experts</HighlightedText>
          </>
        }
      />
      <Testimonial
        heading={
          <>
            Our Participants <HighlightedText>Love Us</HighlightedText>
          </>
        }
      />

      <ContactUsForm />

      <Footer />
    </AnimationRevealPage>
  );
};
