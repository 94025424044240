import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro"; 

import { LogoLink } from "components/headers/light.js";
import { SectionHeading as HeadingBase } from "components/misc/Headings";

import logo from "images/logo.png";

/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 lg:mr-12 last:mr-0 text-gray-700 border-gray-400 hocus:border-gray-700 `;
const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-primary-800 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline`;
const HeroRow = tw(Row)`max-w-xl flex-col justify-between items-center py-20 lg:py-24 mx-auto`;

const Heading = tw(HeadingBase)`text-center text-primary-900 leading-snug`;

export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  window.gtag("js", new Date());
  window.gtag("config", "UA-45799926-9");

  const downloadUrl = "/treact-ui.zip"
  React.useEffect(() => {
    var iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = downloadUrl
    document.body.appendChild(iframe);
  }, [])

  return (
    <AnimationRevealPage disabled>
      <Container tw="-mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <NavRow>
            <LogoLink href="/">
              <img src={logo} alt="logo" />
            </LogoLink>
            <div tw="flex flex-col lg:flex-row items-center">
              <NavLink href="/#about">
                About
              </NavLink>
              <NavLink href="/#workshop">
                Workshop
              </NavLink>
              <NavLink href="/#testimonials">
                Testimonials
              </NavLink>
              <PrimaryNavLink href="/#contact">
                Contact Us
              </PrimaryNavLink>
            </div>
          </NavRow>
          <HeroRow>
            <Heading>Thank you for your interest!</Heading>
            {/* <Description tw="mt-12"></Description>
            <div tw="mt-12 text-center">
            
            </div> */}
            <div tw="mt-12 text-center">
            Your request is now being reviewed. We will get back to you as soon as possible. In the mean time, you may want to check out our LinkedIn pages with the most up-to-date news about the program. If you have any further enquiries, feel free to send us another message through the contact form. 
              <div tw="mt-12 flex flex-col items-center">
                <a
                  href="https://www.linkedin.com/company/greenie-web/"
                  data-size="large"
                  data-show-count="true"

                >
              LinkedIn: <span tw="underline underline-offset-1">Greenie Web</span>
                </a>
              </div>
            </div>
          </HeroRow>
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};
