import 'slick-carousel/slick/slick.css';
import React, { useState } from 'react';
import Slider from 'react-slick';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings.js';
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts.js';
import loveIllustrationImageSrc from 'images/undraw_shared_goals_re_jvqd.svg';
import { ReactComponent as StarIconBase } from 'images/star-icon.svg';
import { ReactComponent as ArrowLeftIcon } from 'images/arrow-left-3-icon.svg';
import { ReactComponent as ArrowRightIcon } from 'images/arrow-right-3-icon.svg';

const Row = tw.div`flex flex-col md:flex-row justify-between items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 xl:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 xl:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:pr-12 lg:pr-16 md:order-first`
    : tw`md:pl-12 lg:pl-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-6 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const TestimonialSlider = styled(Slider)`
  ${tw`w-full mt-10 text-center md:text-left`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;

const Testimonial = tw.div`outline-none h-full flex! flex-col`;
const StarsContainer = styled.div``;
const StarIcon = tw(
  StarIconBase
)`inline-block w-5 h-5 text-orange-400 fill-current mr-1 last:mr-0`;
const TestimonialHeading = tw.div`mt-4 text-xl font-bold`;
const Quote = tw.blockquote`mt-4 mb-8 sm:mb-10 leading-relaxed font-medium text-gray-700`;

const CustomerInfoAndControlsContainer = tw.div`mt-auto flex justify-between items-center flex-col sm:flex-row`;

const CustomerInfo = tw.div`flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerProfilePicture = tw.img`rounded-full w-16 h-16 sm:w-20 sm:h-20`;
const CustomerTextInfo = tw.div`text-center md:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h5`font-bold text-xl`;
const CustomerTitle = tw.p`font-medium text-secondary-100`;

const Controls = styled.div`
  ${tw`flex mt-8 sm:mt-0`}
  .divider {
    ${tw`my-3 border-r`}
  }
`;
const ControlButton = styled.button`
  ${tw`mx-3 p-4 rounded-full transition duration-300 bg-gray-200 hover:bg-gray-300 text-primary-500 hover:text-primary-700 focus:outline-none focus:shadow-outline`}
  svg {
    ${tw`w-4 h-4 stroke-3`}
  }
`;

export default ({
  imageSrc = loveIllustrationImageSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  subheading = 'Testimonials',
  heading = 'Our Participants Love Us.',
  description = 'We have partnered with 24+ institutions and 790+ and here are some feedback we have received about the program.',
  textOnLeft = false,
  testimonials = [
    {
      stars: 5,
      profileImageSrc:
        'https://api.dicebear.com/6.x/micah/svg?seed=Cali&baseColor=f9c9b6&earrings[]&ears=attached&eyeShadowColor[]&eyes=eyes,eyesShadow,round&facialHairProbability=0&glasses[]&glassesProbability=0&hair=pixie,full&mouth=laughing,smile,smirk&nose=pointed&shirt=crew&shirtColor=6bd9e9,9287ff,d2eff3,e0ddff,f4d150,f9c9b6,fc909f,ffeba4,ffedef,ffffff',
      heading: 'Amazing Workshop',
      quote:
        'Workshop was very helpful for our awareness toward digital sustainability. The topic was communicated across very clearly and I am very grateful for the session.',
      customerName: 'Pune University, India',
      customerTitle: 'Student',
    },
    {
      stars: 10,
      profileImageSrc:
        'https://api.dicebear.com/6.x/micah/svg?seed=Snickers&baseColor=f9c9b6&earrings[]&ears=attached&eyeShadowColor[]&eyes=smiling&facialHairProbability=0&glasses[]&glassesProbability=0&hair=pixie&mouth=laughing&nose=pointed&shirt=crew&shirtColor=6bd9e9,9287ff,d2eff3,e0ddff,f4d150,f9c9b6,fc909f,ffeba4,ffedef,ffffff',
      heading: 'Love the Experience and Sustainable Principles!',
      quote:
        'Attended the Web 28 by 28 Program Workshop where I found learning about digital decarbonisation intriguing. Thank you for the informative session!',
      customerName: 'University of Queensland, Australia',
      customerTitle: 'Student',
    },
    {
      stars: 5,
      profileImageSrc:
        'https://api.dicebear.com/6.x/micah/svg?seed=Cali&baseColor=f9c9b6&earrings[]&ears=attached&eyeShadowColor[]&facialHairProbability=0&glasses[]&glassesProbability=0&hair=fonze&mouth=laughing,smile,smirk&nose=curve&shirt=collared&shirtColor=6bd9e9,9287ff,d2eff3,e0ddff,f4d150,f9c9b6,fc909f,ffeba4,ffedef,ffffff',
      heading: 'Great program with in depth knowledge!',
      quote:
        'Truly eye-opening! The workshop both broadened my horizons and provided me with practical steps to play a role in reducing my digital carbon footprint.',
      customerName: 'University of Tampere, Finland',
      customerTitle: 'Student',
    },
  ],
}) => {
  const [sliderRef, setSliderRef] = useState(null);

  return (
    <Container id="testimonials">
      <ContentWithPaddingXl>
        <Row>
          <ImageColumn>
            <Image
              src={imageSrc}
              imageBorder={imageBorder}
              imageShadow={imageShadow}
              imageRounded={imageRounded}
            />
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <TestimonialSlider arrows={false} ref={setSliderRef}>
              {testimonials.map((testimonial, index) => (
                <Testimonial key={index}>
                  <StarsContainer>
                    {testimonial.stars <= 5 ? Array.from({ length: testimonial.stars }).map(
                      (_, indexIcon) => (
                        <StarIcon key={indexIcon} />
                      )
                    ) : <>10/10</>}
                    
                  </StarsContainer>
                  <TestimonialHeading>{testimonial.heading}</TestimonialHeading>
                  <Quote>{testimonial.quote}</Quote>
                  <CustomerInfoAndControlsContainer>
                    <CustomerInfo>
                      <CustomerProfilePicture
                        src={testimonial.profileImageSrc}
                        alt={testimonial.customerName}
                      />
                      <CustomerTextInfo>
                        <CustomerName>{testimonial.customerName}</CustomerName>
                        <CustomerTitle>
                          {testimonial.customerTitle}
                        </CustomerTitle>
                      </CustomerTextInfo>
                    </CustomerInfo>
                    <Controls>
                      <ControlButton onClick={sliderRef?.slickPrev}>
                        <ArrowLeftIcon />
                      </ControlButton>
                      <div className="divider" />
                      <ControlButton onClick={sliderRef?.slickNext}>
                        <ArrowRightIcon />
                      </ControlButton>
                    </Controls>
                  </CustomerInfoAndControlsContainer>
                </Testimonial>
              ))}
            </TestimonialSlider>
          </TextColumn>
        </Row>
      </ContentWithPaddingXl>
    </Container>
  );
};
