import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";


import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import eduIcon from 'images/education.svg'
import speakerIcon from 'images/speaker.svg'
import targetIcon from 'images/target.svg'
import computerIcon  from 'images/computer.svg'
import chargeIcon from 'images/charge.svg'
import knowledgeIcon from 'images/knowledge.svg'


const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-8 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-6`}
  .imageContainer {
    ${tw`border text-center rounded-full p-4 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "Amazing Features", subheading = "About",id, description = "Singapore-based ClimateTech pioneer, Greenie Web, proudly unveils the official launch of its visionary 28 by 28 Program." }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: eduIcon,
      title: "Educative Workshop",
      description: "This workshop aim to educate about sustainable digitization in support of the COP28 initiatives."
    },
    { imageSrc: speakerIcon, title: "Speaker" ,
    description: "Ian Chew (Greenie Web CEO) with more than 14 years experience in digital sustainability field."},
    { imageSrc: targetIcon, title: "Target Audience" ,
    description: "The workshop tailored for students, developers, and all enthusiasts passionate about shaping a greener tech future."},
    { imageSrc: computerIcon, title: "Format",
    description: "The workshop will generally be an online interative workshop. But, we have arranged many offline and hybrid workshop before." },
    { imageSrc: knowledgeIcon, title: "No Assumed Knowledge",
    description: "We do not require any prior knowledge as this is a niche topic. You just need to bring your curiosity." },
    { imageSrc: chargeIcon, title: "Free of Charge",
    description: "The workshop will be free of charge with no hidden or additional fees." }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container id={id}>

      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc } alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
